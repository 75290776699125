<template>
  <div class="albums">
    <AlbumCover v-for="album in albums.data.albums" :key="album.id" :album="album" @album="onShowAlbum" />
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

import AlbumCover from '../../components/AlbumCover'

export default {

  name: 'Albums',

  components: {
    AlbumCover
  },

  computed: mapState(['albums']),

  methods: {

    ...mapActions(['setAlbum']),

    onShowAlbum (slug) {
      this.setAlbum(slug)
      this.$router.push(`/albums/${slug}`)
    }
  }
}
</script>

<style lang="postcss" scoped>

  .albums {
    @apply grid grid-cols-1 gap-6 px-4 pt-2
  }

  @screen md {
    .albums {
      @apply grid-cols-3
    }
  }

  @screen lg {
    .albums {
      @apply grid-cols-4 px-0
    }
  }

</style>
