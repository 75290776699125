import { mapActions } from 'vuex'

import ALBUMS from '../graphql/Albums.gql'
import TEST_ALBUMS from '../graphql/development/TestAlbums.gql'

export default {

  methods: {

    ...mapActions(['setAlbums', 'setAlbum']),

    async loadData () {
      const albums = process.env.NODE_ENV === 'development' ? TEST_ALBUMS : ALBUMS
      const data = await this.$apollo.query({ query: albums })
      await this.setAlbums({ ...data })
      if (this.$route.params && this.$route.params.slug) {
        this.setAlbum(this.$route.params.slug)
      }
    }
  },

  mounted () {
    this.loadData()
  }
}
