<template>
  <Auth title="Create Account" :error="error">
    <FormInput label="Email Address" type="email" v-model="email" />
    <FormInput label="Password" type="password" v-model="password" />
    <FormInput label="Verify Password" type="password" v-model="password2" />
    <FormGroup class="flex items-center justify-between pt-6">
      <FormButton :disabled="!formValid" @click="onClick">Sign Up</FormButton>
      <router-link class="link" :to="{ name: 'Login' }">Log In</router-link>
    </FormGroup>
  </Auth>
</template>

<script>

import Auth from './Auth'
import AdminForms from '@/mixins/AdminForms'

export default {

  name: 'signup',

  mixins: [AdminForms],

  components: {
    Auth
  },

  data () {
    return {
      email: '',
      password: '',
      password2: '',
      error: ''
    }
  },

  computed: {
    formValid () {
      return this.email && this.password && this.password2 && this.password === this.password2
    }
  },

  methods: {
    async onClick () {
      if (this.formValid) {
        this.error = ''
        try {
          await this.$realm.emailPasswordAuth.registerUser(this.email, this.password)
          this.$router.push('/admin/login/signup-success')
        } catch (err) {
          this.error = err.error
        }
      } else {
        this.error = 'Form not valid or passwords don\'t match'
      }
    }
  }
}
</script>
