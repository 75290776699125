import Vue from 'vue'
import * as Realm from 'realm-web'

const app = new Realm.App({ id: 'cjenkins-gmwia', timeout: 10000 })

const Plugin = {}
Plugin.install = (Vue) => {
  Vue.realm = app
  Vue.isAdmin = () => app.currentUser && ['geoffrey.richey@gmail.com', 'caj.mac.1@gmail.com'].indexOf(app.currentUser.profile.email) !== -1
  Object.defineProperties(Vue.prototype, {
    $realm: {
      get () {
        return app
      }
    }
  })
}

Vue.use(Plugin)

const getValidAccessToken = async () => {
  if (!app.currentUser) {
    // If no user is logged in, log in an anonymous user
    console.log('Logging in anon')
    await app.logIn(Realm.Credentials.anonymous())
  } else {
    // The logged in user's access token might be stale,
    // Refreshing custom data also refreshes the access token
    try {
      await app.currentUser.refreshCustomData()
    } catch (err) {
      await app.logIn(Realm.Credentials.anonymous())
    }
  }
  // Get a valid access token for the current user
  return `Bearer ${app.currentUser.accessToken}`
}

export { app, getValidAccessToken }
