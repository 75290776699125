<template>
  <div class="home">

    <transition v-if="fontsLoaded" name="header" @after-enter="onAfterEnter" appear>
      <div class="header" key="header">
        <div class="header-inner">

          <div class="sub sub-left">
            <router-link to="/albums">
              <transition name="fade" appear>
                <img v-if="isAlbum" class="home" src="~@/assets/home.png" key="home" />
              </transition>
            </router-link>
          </div>

          <div class="title">

            <nav>
              <transition name="fade-nav" mode="out-in" appear>
                <router-link v-if="previousLink" class="anav mr-12" :to="`/albums/${previousSlug}`">
                  <svg class="anav-svg newer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.77 61.55"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_2-2" data-name="Layer 2"><polygon class="anav-arrow" points="33.77 58.56 5.99 30.78 33.77 2.99 30.78 0 0 30.78 30.78 61.55 33.77 58.56"/></g></g></svg>
                  <span class="anav-label">newer</span>
                </router-link>
              </transition>
            </nav>

            <transition name="fade" mode="out-in" appear>
              <span v-if="!isAlbum" key="title">The Jenkins Family</span>
              <span class="album-title" v-else-if="currentAlbum" :key="currentAlbum.title">{{ currentAlbum.title }}</span>
            </transition>

            <nav>
              <transition name="fade-nav" mode="out-in" appear>
                <router-link v-if="nextLink" class="anav ml-12" :to="`/albums/${nextSlug}`">
                  <span class="anav-label">older</span>
                  <svg class="anav-svg older" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.77 61.55"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_2-2" data-name="Layer 2"><polygon class="anav-arrow" points="33.77 58.56 5.99 30.78 33.77 2.99 30.78 0 0 30.78 30.78 61.55 33.77 58.56"/></g></g></svg>
                </router-link>
              </transition>
            </nav>

          </div>

          <div class="sub sub-right"></div>

        </div>
      </div>
    </transition>

    <div v-if="!hidden" class="content">
      <transition name="grow" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </div>

    <div v-if="$mq === 'sm'" class="mobile-nav">

      <transition name="fade" appear>
        <router-link to="/">
          <img v-if="isAlbum" class="home" src="~@/assets/home.png" key="home" />
        </router-link>
      </transition>

      <transition name="fade-nav" mode="out-in" appear>
        <div>
          <router-link v-if="previousLink" class="anav" :to="`/albums/${previousSlug}`">
            <svg class="anav-svg newer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.77 61.55"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_2-2" data-name="Layer 2"><polygon class="anav-arrow" points="33.77 58.56 5.99 30.78 33.77 2.99 30.78 0 0 30.78 30.78 61.55 33.77 58.56"/></g></g></svg>
          </router-link>
        </div>
      </transition>

      <transition name="fade-nav" mode="out-in" appear>
        <div>
          <router-link v-if="nextLink" class="anav" :to="`/albums/${nextSlug}`">
            <svg class="anav-svg older" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.77 61.55"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_2-2" data-name="Layer 2"><polygon class="anav-arrow" points="33.77 58.56 5.99 30.78 33.77 2.99 30.78 0 0 30.78 30.78 61.55 33.77 58.56"/></g></g></svg>
          </router-link>
        </div>
      </transition>

    </div>

  </div>
</template>

<script>

import { mapState } from 'vuex'

import LoadAlbums from '@/mixins/LoadAlbums'

export default {

  name: 'Home',

  mixins: [LoadAlbums],

  data () {
    return {
      fontsLoaded: false,
      hidden: true
    }
  },

  computed: {

    ...mapState(['currentAlbum', 'previousSlug', 'nextSlug']),

    isAlbum () {
      return this.$route.name === 'Album'
    },

    previousLink () {
      return this.isAlbum && this.previousSlug
    },

    nextLink () {
      return this.isAlbum && this.nextSlug
    }
  },

  methods: {

    onAfterEnter () {
      this.hidden = false
      this.$nextTick(() => {
        window.dispatchEvent(new Event('resize'))
      })
    }
  },

  mounted () {
    if (document.tkFontsLoaded) {
      this.fontsLoaded = true
    } else {
      document.addEventListener('tkFontsLoaded', () => {
        this.fontsLoaded = true
      })
    }
  }
}
</script>

<style lang="postcss" scoped>

  /* .home {
    @apply mb-12
  } */

  .header {
    background: url(~@/assets/dark_leather.png);
    @apply fixed top-0 inset-x-0 z-30 shadow-lg text-white
  }

  .header-inner {
    height: 60px;
    @apply container mx-auto flex items-center justify-between
  }

  .title {
    @apply h-full flex-1 text-xl font-header text-center flex items-center justify-center
  }

  nav {
    @apply hidden h-full
  }

  .anav {
    @apply flex items-center h-full
  }

  .anav-label {
    transition: transform 0.34s ease;
    @apply font-default text-xl
  }

  .anav-svg {
    transition: transform 0.51s ease;
    height: 20px;
    @apply mx-2
  }

  .anav-svg.older {
    transform: rotate(180deg);
  }

  .anav-arrow {
    fill: #f6efef;
  }

  .anav:hover .anav-label {
    transform: scale(1.1);
  }

  .anav:hover .anav-svg.newer {
    transform: translateX(-4px);
  }

  .anav:hover .anav-svg.older {
    transform: rotate(180deg) translateX(-4px);
  }

  .content {
    min-height: calc(100vh - 120px);
    margin-top: 60px;
    @apply pb-20 relative
  }

  .sub {
    @apply hidden
  }

  .sub a {
    @apply h-full block flex items-center
  }

  .sub-left {
    @apply ml-4
  }

  .sub-right {
    @apply mr-4
  }

  /* Header */
  .header-enter-active {
    /* transition: all 0.64s ease 1s; */
    animation: header-in 2s ease;
  }

  .mobile-nav {
    height: 60px;
    @apply flex items-center
  }

  .mobile-nav > * {
    flex-basis: 33%;
    @apply text-center flex items-center justify-center h-full
  }

  .mobile-nav .home {
    height: 36px;
  }

  /* .header-enter {
    transform: translateY(45vh) scale(2);
    background: transparent;
  } */

  @keyframes header-in {
    0% {
      opacity: 0;
      transform: translateY(45vh) scale(2);
      background: transparent;
    }
    50% {
      transform: translateY(45vh) scale(2);
    }
    99% {
      background: transparent;
    }
  }

  /* Grow in */
  .grow-enter-active, .grow-leave-active {
    transition: all .34s ease;
  }

  .grow-enter, .grow-leave-to {
    transform: scale(0.64);
    opacity: 0;
  }

  /* Fade in */
  .fade-enter-active, .fade-leave-active,
  .fade-nav-leave-active {
    transition: opacity .34s linear;
  }
  .fade-nav-enter-active {
    transition: opacity .34s linear 1s;
  }
  .fade-enter, .fade-leave-to,
  .fade-nav-enter, .fade-nav-leave-to {
    opacity: 0;
  }

  @screen md {

    nav {
      width: 125px;
      color: #f6efef;
      @apply block;
    }

    .home {
      @apply container mx-auto
    }

    .title {
      @apply text-3xl
    }

    .header-inner {
      height: 76px;
    }

    .content {
      min-height: calc(100vh - 76px);
      @apply mt-20
    }

    .sub {
      width: 40px;
      @apply h-full flex items-center
    }
  }

  @screen lg {
    .album-title {
      width: 360px;
    }
  }

</style>
