<template>
  <div class="login">
    <div class="login-outer">
      <form class="login-form">
        <div class="login-title">{{ title }}</div>
        <slot></slot>
      </form>
      <div class="login-status">
        <span class="login-error" v-if="error">{{ error }}</span>
        <span class="login-success" v-if="success">{{ success }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'auth',
  props: ['title', 'error', 'success']
}
</script>

<style lang="postcss" scoped>

  .login {
    @apply fixed inset-0 flex items-center justify-center font-admin
  }

  .login-outer {
    @apply w-5/6
  }

  .login-form {
    box-shadow: 0 0 24px 24px rgba(200, 200, 200, 0.01);
    background-color: rgba(0,0,0, 0.1);
    @apply text-white w-full rounded py-4 px-8
  }

  .login-title {
    @apply font-bold text-2xl text-center text-gray-200 mb-12 mt-2
  }

  .login-status {
    @apply h-10 text-center pt-4
  }

  @keyframes fadeIn {
    from { opacity: 0 }
    to { opacity: 1 }
  }

  .login-status span {
    animation: fadeIn 0.64s linear;
    @apply bg-red-600 inline-block bg-opacity-20 text-gray-300 px-4 py-1 rounded-full text-sm font-bold
  }

  .login-status span.login-success {
    @apply bg-green-600 bg-opacity-20
  }

  @screen md {
    .login-outer {
      @apply w-96
    }
  }

</style>
