<template>
  <Auth title="Login" :error="error" :success="signupComplete">
    <FormInput label="Email Address" type="email" v-model="email" />
    <FormInput label="Password" type="password" v-model="password" />
    <FormGroup class="flex items-center justify-between pt-6">
      <FormButton :disabled="disabled"  @click="onClick">Log In</FormButton>
      <router-link class="link" to="/admin/signup">Sign Up</router-link>
    </FormGroup>
  </Auth>
</template>

<script>

import * as Realm from 'realm-web'

import Auth from './Auth'
import AdminForms from '@/mixins/AdminForms'

export default {

  name: 'login',

  mixins: [AdminForms],

  components: {
    Auth
  },

  props: {
    /**
     * Signup Status
     */
    signup: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      email: '',
      password: '',
      error: '',
      success: '',
      authenticating: false
    }
  },

  computed: {

    formValid () {
      return this.email.length && this.password.length
    },

    disabled () {
      return !this.formValid || this.authenticating
    },

    signupComplete () {
      return this.signup ? 'Account created!' : null
    }
  },

  methods: {
    async onClick () {
      if (!this.disabled) {
        this.error = ''
        const credentials = Realm.Credentials.emailPassword(this.email, this.password)
        try {
          this.authenticating = true
          await this.$realm.logIn(credentials)
          this.$nextTick(() => {
            this.$router.push({ name: 'Dash' })
          })
        } catch (err) {
          this.authenticating = false
          this.error = err.error
        }
      } else if (!this.formValid) {
        this.error = 'Email and Password Required'
      }
    }
  }
}
</script>
