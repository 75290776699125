<template>
  <FormGroup v-bind="$attrs">

    <template v-slot:default="scope">
      <input v-bind="$attrs"
        @input="e => $emit('input', e.target.value)"
        @focus="scope.focus"
        @blur="scope.blur"  />
    </template>

    <template v-if="$slots['label-right']" v-slot:label-right>
      <slot name="label-right"></slot>
    </template>

  </FormGroup>
</template>

<script>
import FormGroup from './FormGroup'
export default {

  name: 'form-input',

  inheritAttrs: false,

  components: {
    FormGroup
  }
}
</script>
