<template>
  <div class="form-group" :class="{ focused }" v-bind="$attrs">

    <label v-if="label">{{ label }}</label>

    <span class="label-right">
      <slot name="label-right"></slot>
    </span>

    <slot :focus="onFocus" :blur="onBlur" v-on="$listeners" v-bind="$attrs"></slot>

  </div>
</template>

<script>
export default {

  name: 'form-group',

  inheritAttrs: false,

  props: ['label'],

  data () {
    return {
      focused: false
    }
  },

  methods: {
    onFocus () {
      this.focused = true
    },

    onBlur () {
      this.focused = false
    }
  }

}
</script>

<style lang="postcss" scoped>

  .form-group {
    transition: opacity 0.64s linear;
    @apply mb-6 relative
  }

  .form-group label {
    @apply uppercase block mb-2 opacity-30
  }

  .form-group .label-right {
    @apply absolute top-0 right-0
  }

  .form-group /deep/ input,
  .form-group /deep/ .dropzone {
    @apply w-full rounded bg-opacity-50 text-gray-200 py-1 px-2 bg-gray-800 shadow-inner border-none
  }

  .form-group /deep/ .link {
    transition: opacity 0.64s linear;
    @apply opacity-60 font-bold
  }

  .form-group /deep/ .link:hover {
    @apply opacity-100
  }

  .form-group.focused label {
    @apply opacity-60
  }

  .form-group input:focus,
  .form-group input:active,
  .form-group.focused input {
    @apply bg-gray-600 bg-opacity-50
  }

  .form-group input:disabled {
    @apply bg-gray-800 bg-opacity-20 text-gray-400
  }

  .form-group /deep/ .dz-clickable {
    @apply relative
  }

  .form-group /deep/ .dz-message {
    @apply relative z-20
  }

  .form-group /deep/ .dz-clickable:after {
    content: "";
    height: 500px;
    width: 300px;
    left: -500px;
    top: -200px;
    transform: rotate(35deg);
    transition: all 640ms cubic-bezier(0.19, 1, 0.22, 1);
    @apply absolute z-10 bg-gradient-to-t from-gray-700 to-gray-800 opacity-30
  }

  .form-group /deep/ .dz-clickable:hover:after {
    left: 150%;
  }

  .form-group /deep/ .dz-clickable:hover .dz-message {
    @apply text-indigo-300
  }

</style>
