<template>
  <div class="cont">
    <div class="body">
      <nav class="nav"><SideBar /></nav>
      <div class="content" ref="content">
        <transition name="portal" mode="out-in" appear>
          <router-view :key="$route.params ? $route.params.slug : $route.name"></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

import LoadAlbums from '@/mixins/LoadAlbums'

import SideBar from '@/components/admin/SideBar'

export default {

  name: 'portal',

  mixins: [LoadAlbums],

  components: {
    SideBar
  }
}
</script>

<style lang="postcss" scoped>

  .cont {
    /* grid-template-rows: 60px 1fr; */
    @apply w-screen h-screen grid grid-cols-1
  }

  .header {
    box-shadow: 4px 0 8px 0 rgba(0,0,0,0.8);
    @apply px-6 bg-gray-500 bg-opacity-10 flex items-center relative z-50
  }

  .body {
    @apply overflow-hidden relative z-40
  }

  .nav {
    box-shadow: 4px -4px 8px 0 rgba(0,0,0,0.8);
    @apply bg-gray-600 bg-opacity-10 relative z-10 pt-1 overflow-y-auto
  }

  .portal-enter-active, .portal-leave-active {
    transition: all 0.64s ease;
  }

  .portal-enter, .portal-leave-to {
    transform: translateX(-24px);
    opacity: 0;
  }

  .content {
    @apply overflow-hidden
  }

  @screen md {

    .body {
      grid-template-columns: 1fr 3fr;
      @apply grid
    }
  }
</style>
