<template>
  <section v-scroll-reveal.reset>
  <div v-ripple="'rgba(255, 255, 255, 0.35)'" class="ac" @click="onAlbum">
    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
      :data-imageboss-src="`/${album.slug}/${album.cover}`"
      sizes="(max-width: 640px) 100vw, 500px" />
    <div class="aci">
      <span class="aci-title">{{ album.title }}</span>
      <span class="aci-date">{{ album.date }}</span>
    </div>
  </div>
  </section>
</template>

<script>

export default {

  name: 'AlbumCover',

  props: {
    album: {
      type: Object,
      required: true
    }
  },

  methods: {
    onAlbum () {
      window.setTimeout(() => {
        this.$emit('album', this.album.slug)
      }, 600)
    }
  }
}
</script>

<style lang="postcss" scoped>
  .ac {
    opacity: 1;
    transition: all 0.3s ease;
    padding-top: 66.6%;
    @apply rounded overflow-hidden relative
  }

  .ac.invisible {
    opacity: 0;
  }

  .ac:hover {
    transform: translateY(-1px);
    @apply cursor-pointer shadow
  }

  .ac img {
    transition: transform 1s ease;
    @apply absolute top-0 inset-x-0 z-10 w-full
  }

  .ac:hover img {
    transform: scale(1.2);
  }

  .aci {
    background: #fff url(~@/assets/white_leather.png);
    @apply flex justify-between items-center px-4 py-2 relative z-20
  }

  .aci-title {
    @apply text-xl tracking-wider
  }

  .aci-date {
    @apply font-thin
  }
</style>
