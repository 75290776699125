<template>
  <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: 'BImg',
  inheritAttrs: false
}
</script>

<style lang="postcss" scoped>

  img {
    transition: all 0.64s ease;
  }

  img.gray {
    filter: grayscale(100%);
  }

  img.gray:hover {
    filter: grayscale(0%);
  }

</style>
