<template>
  <div class="album" ref="container">
    <transition name="fade">
      <vue-masonry-gallery v-if="showGrid"
        ref="waterfall"
        :imgsArr="images"
        :perPage="perPage"
        :maxCols="maxCols"
        :imgWidth="imgWidth"
        :reachBottomDistance="400"
        @scrollReachBottom="nextPage"
        @click="onClick"
        @preloaded="onPreloaded">
        <div slot="waterfall-over">No More Images</div>
      </vue-masonry-gallery>
    </transition>

    <LightBox ref="lightbox"
      v-if="media.length"
      :media="media"
      :show-thumbs="false"
      :show-light-box="false"
      :length-to-load-more="4"
      @onLoad="nextPage"></LightBox>

  </div>
</template>

<script>

import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'
import LightBox from '@/lib/vue-image-lightbox'
import PinchZoom from 'vue-pinch-zoom'

import { mapState, mapActions } from 'vuex'

import VueMasonryGallery from 'vue-masonry-gallery/vendor/VueMasonryGallery/VueMasonryGallery'

require('@/lib/vue-image-lightbox/dist/vue-image-lightbox.min.css')

Vue.use(VueLazyLoad)
Vue.component('pinch-zoom', PinchZoom)

export default {

  name: 'Album',

  components: {
    VueMasonryGallery,
    LightBox
  },

  data () {
    return {
      showGrid: true,
      page: 0,
      perPage: 8,
      images: []
    }
  },

  computed: {
    ...mapState(['currentAlbum']),

    maxCols () {
      const m = this.$mq
      window.dispatchEvent(new Event('resize'))
      return m === 'sm' ? 2 : 5
    },

    imgWidth () {
      const m = this.$mq
      window.dispatchEvent(new Event('resize'))
      return m === 'sm' ? 150 : (m === 'md' ? 350 : (m === 'lg' ? 300 : 300))
    },

    media () {
      return this.currentAlbum ? this.currentAlbum.images.map(filename => {
        const resize = this.$mq === 'sm' ? 'width/500' : 'height/800'
        return {
          thumb: `${process.env.VUE_APP_IB_URL}/${resize}/format:webp,dpr:${parseInt(window.devicePixelRatio)}/${this.currentAlbum.slug}/large/${filename}`,
          src: `${process.env.VUE_APP_IB_URL}/${resize}/format:webp,dpr:${parseInt(window.devicePixelRatio)}/${this.currentAlbum.slug}/large/${filename}`
        }
      }) : []
    }
  },

  methods: {

    ...mapActions(['setAlbum']),

    onPreloaded () {
      const vm = this.$refs.waterfall
      if (vm) {
        if (!vm.colsHeightArr.length) {
          this.nextPageWait()
        } else {
          const maxHeight = Math.max.apply(null, vm.colsHeightArr)
          const contHeight = this.$refs.container.clientHeight
          if (maxHeight < contHeight) {
            this.nextPageWait()
          }
        }
      }
    },

    nextPageWait () {
      window.setTimeout(() => {
        this.nextPage()
      }, 300)
    },

    nextPage () {
      this.page++
      this.setImages()
    },

    setImages () {
      const start = this.page * this.perPage
      const end = (this.page + 1) * this.perPage
      const realEnd = end <= this.currentAlbum.images.length ? end : this.currentAlbum.images.length

      if (start >= realEnd) {
        window.setTimeout(() => { this.$refs.waterfall.masonryOver() }, 200)
        return
      }

      const images = this.currentAlbum.images.slice(start, realEnd).map(filename => {
        const uri = `${process.env.VUE_APP_IB_URL}/width/300/format:webp,dpr:2/${this.currentAlbum.slug}/large/${filename}`
        return {
          src: uri,
          href: uri
        }
      })

      this.images = this.images.concat(images)
    },

    onClick (e, { index }) {
      e.preventDefault()
      this.$refs.lightbox.showImage(index)
    }
  },

  mounted () {
    if (this.currentAlbum) {
      this.setImages()
    }
  },

  watch: {

    currentAlbum (newVal, oldVal) {
      if (newVal && !oldVal) {
        window.setTimeout(() => {
          this.setImages()
        }, 1000)
      }
    },

    $route (newVal) {
      this.showGrid = false
      this.images = []
      this.page = 0
      this.setAlbum(newVal.params.slug).then(() => {
        this.setImages()
        this.showGrid = true
      })
    }
  }
}
</script>

<style lang="postcss" scoped>
  .album {
    @apply absolute inset-0
  }

  .album /deep/ .vue-masonry-gallery-container > .loading.ball-beat > .dot {
    @apply bg-gray-100 !important
  }

  .album /deep/ .vue-lb-modal-image {
    max-height: calc(100vh - 125px) !important;
  }

  .album /deep/ .vue-lb-arrow {
    outline: none;
  }
</style>
