<template>
  <ul class="album-nav">
    <li v-if="$route.name !== 'Create'" class="album">
        <FormButton class="green w-full" @click="createAlbum">+ New Album</FormButton>
    </li>
    <li class="album" v-for="album in albums.data.albums" :key="album.slug">
      <FormButton class="nav" :class="{ active: isActive(album.slug) }" @click="() => editAlbum(album.slug)">
        <div class="flex items-center text-left">
          <BImg
            class="album-img"
            :class="{ gray : !isActive(album.slug) }"
            :data-imageboss-src="`/${album.slug}/${album.cover}`"
            data-imageboss-operation="cover"
            data-imageboss-cover-mode="center"
            width="48"
            height="48" />
          {{ album.title }}
        </div>
      </FormButton>
    </li>
  </ul>
</template>

<script>

import { mapState } from 'vuex'

import BImg from '../BImg'
import FormButton from './FormButton'

export default {

  name: 'sidebar',

  components: {
    FormButton,
    BImg
  },

  computed: mapState(['albums', 'currentAlbum']),

  methods: {

    isActive (slug) {
      return this.$route.params && this.$route.params.slug === slug
    },

    editAlbum (slug) {
      const to = `/admin/portal/edit/${slug}`
      if (this.$route.path !== to) this.$router.push(to)
    },

    createAlbum () {
      if (this.$route.name !== 'Create') this.$router.push({ name: 'Create' })
    }
  }
}
</script>

<style lang="postcss" scoped>

  .album-nav {
    @apply m-2
  }

  .album {
    @apply my-2
  }

  .album-img {
    @apply rounded mr-3
  }

  .new-ph {
    @apply p-5 border-2 border-gray-800 rounded text-gray-600 font-bold
  }

</style>
