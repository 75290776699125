import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    albums: {
      data: {},
      loading: false,
      stale: false
    },
    currentAlbum: null,
    nextSlug: null,
    previousSlug: null
  },

  mutations: {

    'SET_ALBUMS' (state, albums) {
      const data = albums.data.albums || albums.data.albumsTests
      const arr = [...data].sort((a, b) => (a.order < b.order) ? 1 : ((a.order > b.order) ? -1 : 0))
      state.albums = {
        ...albums,
        data: {
          albums: [...arr]
        }
      }
    },

    'SET_ALBUM' (state, slug) {
      const albums = state.albums.data.albums
      if (!albums) return
      for (let i = 0; i < albums.length; i++) {
        const o = albums[i]
        if (o.slug === slug) {
          state.currentAlbum = o
          state.previousSlug = (i > 0) ? albums[i - 1].slug : null
          state.nextSlug = (i + 1 < albums.length) ? albums[i + 1].slug : null
        }
      }
    },

    'CLEAR_ALBUM' (state) {
      state.currentAlbum = null
      state.previousSlug = null
      state.nextSlug = null
    },

    'PUBLISH_ALBUM' (state, context) {
      const album = state.albums.data.albums.find(o => o.slug === context.slug)
      if (album) {
        album.slug = context.slug
        album.title = context.title
        album.cover = context.cover
        album.date = context.date
        album.images = context.images
      } else {
        state.albums.data.albums = [context, ...state.albums.data.albums]
      }
    }
  },

  actions: {

    setAlbums ({ commit }, context) {
      commit('SET_ALBUMS', context)
    },

    setAlbum ({ commit }, slug) {
      commit('SET_ALBUM', slug)
    },

    async clearAlbum ({ commit }) {
      commit('CLEAR_ALBUM')
    },

    publishAlbum ({ commit }, context) {
      commit('PUBLISH_ALBUM', context)
    }
  }
})
