import Vue from 'vue'
import VueRouter from 'vue-router'
import VueBodyClass from 'vue-body-class'

import Home from '../views/Home.vue'
import Albums from '../views/home/Albums'
import Album from '../views/home/Album'

import Admin from '../views/Admin'
import Login from '../views/admin/Login'
import Signup from '../views/admin/Signup'
import Portal from '../views/admin/Portal'

const Dash = () => import('../views/admin/portal/Dash')
const Edit = () => import('../views/admin/portal/Edit')
const Create = () => import('../views/admin/portal/Create')

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/albums' },
  {
    path: '/albums',
    component: Home,
    children: [
      {
        path: '',
        name: 'Albums',
        component: Albums
      },
      {
        path: ':slug',
        name: 'Album',
        component: Album
      }
    ]
  },

  {
    path: '/admin',
    component: Admin,
    meta: { bodyClass: 'overflow-hidden' },
    children: [
      {
        path: '',
        name: 'Login',
        component: Login
      },
      {
        path: 'login/signup-success',
        name: 'Login Success',
        component: Login,
        props: { signup: true }
      },
      {
        path: 'signup',
        name: 'Signup',
        component: Signup
      },
      {
        path: 'portal',
        component: Portal,
        meta: {
          adminSecure: true
        },
        children: [
          {
            path: '',
            name: 'Dash',
            component: Dash
          },
          {
            path: 'edit/:slug',
            name: 'Edit',
            component: Edit,
            props: true
          },
          {
            path: 'create',
            name: 'Create',
            component: Create
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

const vueBodyClass = new VueBodyClass(routes)
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next)
  const adminSecure = to.matched.slice().reverse().find(r => r.meta && r.meta.adminSecure)
  if (to.name === 'Login' && Vue.isAdmin()) {
    return next({ name: 'Dash' })
  } else if (adminSecure && !Vue.isAdmin()) {
    return next({ name: 'Login' })
  }
  return next()
})

export default router
