import FormInput from '../components/admin/FormInput'
import FormGroup from '../components/admin/FormGroup'
import FormButton from '../components/admin/FormButton'

export default {
  components: {
    FormInput,
    FormGroup,
    FormButton
  }
}
